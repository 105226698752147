import { Store, createStore, useStore as baseUseStore } from 'vuex';
import {
  KycStatus,
  MutedUser,
  MyUserInfo,
  SignUpStep,
} from '@/types/MyUserInfo';
import { State } from '@/types/State';
import { serverAddress, getAxiosConfig, removeLeadingZeros } from '@/helpers/appConfig';
import { DispatchMode, PopupView } from '@/types/PopupView';
import axios from 'axios';
import { InjectionKey } from 'vue';
import { PackageInfo } from '@/types/PackageInfo';
import { PostInfo } from '@/types/PostInfo';
import { BatchShippingInfo } from '@/types/BatchShippingInfo';
import { OrderInfo, OrderStatus } from '@/types/OrderInfo';
import { ChatGroup } from '@/types/MessageInfo';
import { TravellersRequestInfo } from '@/types/TravellersRequestInfo';
import { TransactionInfo } from '@/types/TransactionInfo';
import { DeliveryRequestInfo } from '@/types/DeliveryRequestInfo';
import { SendmeNotification } from '@/types/SendmeNotification';
import { DeliveryInfo } from '@/types/DeliveryInfo';

export const key: InjectionKey<Store<State>> = Symbol('make store strongly typed');

export default createStore<State>({
  state: {
    user: null,
    tempUser: null, // temporarily store user info between views during signup
    tempPackageInfo: null, // temporarily store user info between views during signup
    tempDeliveryInfo: null, // temporarily store user info between views during signup
    signupStep: SignUpStep.Identification,
    requestToken: '',
    isLoggedIn: false,
    toggleSidebar: false,
    activeViewIndex: 0,
    popupView: PopupView.Hidden,
    sendPackageCharge: 500,
    uploadedFiles: [],
    activeTimelineItem: undefined,
    activeRelatedTimelineItem: undefined,
    activeOrderItem: undefined,
    activeTransactionItem: undefined,
    refreshOrdersTable: false,
    chatGroups: [],
    showBalance: false,
    shouldShowBanner: true,
    showOnboarding: false,
    showSearch: false,
    newLiveNotificationMessages: [],
  },
  mutations: {
    SET_USER(state: State, value: MyUserInfo) {
      state.user = value;
    },
    SET_TEMP_USER(state: State, value: MyUserInfo) {
      state.tempUser = value === null ? {
        fullName: '',
        username: '',
        email: '',
        password: '',
        uid: '',
        id: '',
        city: '',
        idToken: '',
        authProvider: 0,
        emailVerified: false,
        isDispatchPartner: false,
        isNigerian: false,
        isBlocked: false,
        kycStatus: KycStatus.Unverified,
        phoneNumber: '',
        referrer: '',
        phoneVerified: false,
        dialCode: '+234',
        countryOfResidence: 'Nigeria',
        countryCode: 'NG',
        currency: '₦',
        webPushNotificationEnabled: false,
        showReadReceipts: false,
        emailOnNewNotifications: false,
        emailOnDirectMessages: false,
        emailPaymentReceipts: false,
        emailOrderPerformance: false,
        rating: 0,
        numberOfDeliveries: 0,
        packagesSent: 0,
        mutedUsers: [],
        wallet: {
          id: 'string',
          balance: 0,
          unclearedBalance: 0,
          isLocked: false,
          hasPin: false,
          currency: 'string',
          dateCreated: new Date(),
        },
        business: {
          id: '',
          businessName: '',
          businessWebsite: '',
          businessEmail: '',
          businessRepRole: '',
          workingHoursStart: { hours: 8, minutes: 0, seconds: 0 },
          workingHoursEnd: { hours: 17, minutes: 0, seconds: 0 },
          numberOfVehiclesInFleet: '',
          businessPhoneNumber: '',
          dialCode: '',
        },
      } : value;
      console.log('dksfsd', state.tempUser);
    },
    SET_SHOW_SEARCH(state: State, value: boolean) {
      state.showSearch = value;
    },
    SET_TEMP_PACKAGEINFO(state: State, value: PackageInfo | null) {
      state.tempPackageInfo = value;
    },
    SET_TEMP_DELIVERYINFO(state: State, value: DeliveryInfo | null) {
      state.tempDeliveryInfo = value;
    },
    SET_SHOW_BALANCE(state: State, value: boolean) {
      state.showBalance = value;
    },
    SET_SIGNUP_STEP(state: State, value: SignUpStep) {
      state.signupStep = value;
    },
    SET_REQ_TOKEN(state: State, value: string) {
      state.requestToken = value;
    },
    SET_LOGIN(state: State, value: boolean) {
      state.isLoggedIn = value;
    },
    SET_SIDEBAR(state: State, value: boolean | null) {
      state.toggleSidebar = value != null ? value : !state.toggleSidebar;
    },
    SET_ACTIVE_VIEW(state: State, value: number) {
      state.activeViewIndex = value;
    },
    SET_POPUP(state: State, value: PopupView) {
      state.popupView = value;
    },
    SET_ACTIVE_TIMELINE_ITEM(state: State, value: (PackageInfo | PostInfo)) {
      state.activeTimelineItem = value;
    },
    SET_UPLOADED_FILES(state: State, value: { fileName: string, publicId: string }[]) {
      state.uploadedFiles = value;
    },
    SET_ACTIVE_BATCH_SHIPPING_ITEM(state: State, value: BatchShippingInfo) {
      state.activeRelatedTimelineItem = value;
    },
    SET_ACTIVE_TRAVELLERS_REQUEST_ITEM(state: State, value: TravellersRequestInfo) {
      state.activeRelatedTimelineItem = value;
    },
    SET_ACTIVE_ORDER_ITEM(state: State, value: OrderInfo) {
      state.activeOrderItem = value;
    },
    SET_ACTIVE_TRANSACTION_ITEM(state: State, value: TransactionInfo) {
      state.activeTransactionItem = value;
    },
    SET_SHOULD_SHOW_COOKIE_BANNER(state: State, value: boolean) {
      state.shouldShowBanner = value;
    },
    SET_SHOW_ONBOARDING(state: State, value: boolean) {
      state.showOnboarding = value;
    },
    SET_REFRESH_ORDERS_TABLE(state: State, value: boolean) {
      state.refreshOrdersTable = value;
    },
    SET_CHAT_GROUPS(state: State, value: ChatGroup[]) {
      state.chatGroups = value;
    },
    SET_ACTIVE_MESSAGE_REQUEST_DETAILS(state: State, value: DeliveryRequestInfo) {
      state.activeDeliveryRequestInfo = value;
    },
    SET_LIVE_NOTIFICATIONS(state: State, value: SendmeNotification[]) {
      state.newLiveNotificationMessages = value;
    },
  },
  actions: {
    initiatePayout({ state }, payload:
      { amount: number, bankCode: number, accountNumber: string, pin: number, accountName: string }) {
      const body = payload;
      return axios.post(
        `${serverAddress}/wallet/initiatepayout`,
        body,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    submitDispatchPartnerToServer({ commit }, dispatcher: MyUserInfo) {
      // lets use an internal function
      const body = {
        username: dispatcher.username,
        referrer: dispatcher.referrer,
        businessName: dispatcher.business!.businessName,
        businessEmail: dispatcher.business!.businessEmail,
        businessWebsite: dispatcher.business!.businessWebsite,
        businessRepName: dispatcher.fullName,
        businessRepRole: dispatcher.business!.businessRepRole,
        businessRepCity: dispatcher.city,
        businessRepCountry: dispatcher.countryOfResidence,
        businessRepCountryCode: dispatcher.countryCode,
        workingHoursStart: dispatcher.business!.workingHoursStart,
        workingHoursEnd: dispatcher.business!.workingHoursEnd,
        password: dispatcher.password,
        numberOfVehiclesInFleet: dispatcher.business?.numberOfVehiclesInFleet,
        pin: dispatcher.wallet.pin,
        pushNotificationToken: '',
        // convert phone number to international format
        businessPhoneNumber: `${dispatcher.dialCode}${removeLeadingZeros(dispatcher.business!.businessPhoneNumber!)}`,
      };
      return axios.post(
        `${serverAddress}/users/dispatchPartner`,
        body,
        getAxiosConfig(''), // empty token since we are not yet registered
      );
    },
    submitUser({ commit }, user: MyUserInfo) {
      commit('SET_TEMP_USER', user);
    },
    submitUserToServer({ commit }, user: MyUserInfo) {
      // lets use an internal function
      const body = {
        id: user.uid,
        email: user.email,
        password: user.password,
        fullName: user.fullName,
        authProvider: user.authProvider,
        username: user.username,
        countryOfResidence: user.countryOfResidence,
        countryCode: user.countryCode,
        referrer: user.referrer,
        city: user.city,
        // convert phone number to international format
        phoneNumber: `${user.dialCode}${removeLeadingZeros(user.phoneNumber)}`,
      };
      return axios.post(
        `${serverAddress}/users`,
        body,
        getAxiosConfig(''), // empty token since we are not yet registered
      );
    },
    submitPackageRequest({ state }, payload:
      { package: PackageInfo, timelineItemId?: string, timelineItemType: number }) {
      const imageIdList = payload.package.images.map((item) => item.publicId);
      const body = {
        package: {
          deliveryPartner: payload.package.deliveryType,
          dispatchMode: payload.package.dispatchMode,
          senderFullName: payload.package.senderFullName,
          ReceiverFullName: payload.package.receiverFullName,
          SenderPhoneNumber:
            `${payload.package.senderDialCode}${removeLeadingZeros(payload.package.senderPhoneNumber)}`,
          ReceiverPhoneNumber:
            `${payload.package.receiverDialCode}${removeLeadingZeros(payload.package.receiverPhoneNumber)}`,
          PickupAddress: payload.package.pickupAddress,
          DropoffAddress: payload.package.dropOffAddress,
          Pin: payload.package.pin,
          Weight: payload.package.weight,
          ItemCategoryId: payload.package.itemCategory.value,
          HandleRequirementId: payload.package.handleRequirement.value,
          ShipmentDate: payload.package.shipmentDate,
          price: payload.package.price,
          costOfItem: payload.package.costOfItem,
          imageIdList,
        },
        relatedTimelineItemType: payload.timelineItemType,
        relatedTimelineItemId: payload.timelineItemId,
      };
      return axios.post(
        `${serverAddress}/timeline/package`,
        body,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    submitTravellersRequest({ state }, payload:
      {
        dispatchMode: DispatchMode,
        pickupAddress: string,
        dropOffAddress: string,
        shipmentDate: Date,
      }) {
      const body = {
        dispatchMode: payload.dispatchMode,
        pickupAddress: payload.pickupAddress,
        dropOffAddress: payload.dropOffAddress,
        shippingDate: new Date(payload.shipmentDate).toISOString(),
      };
      return axios.post(
        `${serverAddress}/timeline/travellersrequest`,
        body,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    submitPost({ state }, post: PostInfo) {
      const imageIdList = [...post.images];
      const body = {
        message: post.message,
        imageIdList,
      };
      return axios.post(
        `${serverAddress}/timeline/post`,
        body,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    submitBatchShippingRequest({ state }, info: BatchShippingInfo) {
      const body = {
        pickupAddress: info.pickupAddress,
        dropOffAddresses: info.dropOffAddresses,
        shippingDate: info.shippingDate,
        priceStart: info.priceStart,
        priceEnd: info.priceEnd,
      };

      return axios.post(
        `${serverAddress}/timeline/batchShipping`,
        body,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    submitPin({ state }, payload: { oldPin: string, newPin: string }) {
      const body = {
        oldPin: payload.oldPin,
        newPin: payload.newPin,
      };
      return axios.put(
        `${serverAddress}/wallet/pin`,
        body,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    sendPinResetOtp({ state }) {
      const body = {
      };
      return axios.post(
        `${serverAddress}/wallet/pin/reset`,
        body,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    verifyPinResetOtp({ state }, payload: string) {
      return axios.put(
        `${serverAddress}/wallet/pin/reset?otpCode=${payload}`,
        {},
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    acceptPackage({ state }, payload: { packageId: string, userId: string, isBooking: boolean }) {
      const body = {
        packageId: payload.packageId,
        userId: payload.userId,
        isBooking: payload.isBooking,
      };
      return axios.post(
        `${serverAddress}/orders`,
        body,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    cancelPackageDelivery({ state }, packageId: string) {
      return axios.delete(
        `${serverAddress}/orders/${packageId}`,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    deletePost({ state }, payload: { itemType: string, itemId: string }) {
      return axios.delete(
        `${serverAddress}/timeline/${payload.itemType}/${payload.itemId}`,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    submitComment({ state }, payload: { itemType: number, itemId: string, comment: string }) {
      const body = {
        itemType: payload.itemType,
        TimelineItemId: payload.itemId,
        message: payload.comment,
      };
      return axios.post(
        `${serverAddress}/timeline/comment`,
        body,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    likeItem({ state }, payload: { itemType: number, itemId: string, }) {
      const body = {};
      return axios.post(
        `${serverAddress}/timeline/like/${payload.itemType}/${payload.itemId}`,
        body,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    unlikeItem({ state }, payload: { itemType: number, itemId: string, }) {
      return axios.delete(
        `${serverAddress}/timeline/like/${payload.itemType}/${payload.itemId}`,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    updateUser(
      { state },
      payload: {
        username?: string,
        phoneNumber?: string,
        profilePicture?: string,
        mutedUserId?: string,
        webPushNotificationToken?: boolean,
        webPushNotificationEnabled?: boolean,
        showReadReceipts?: boolean,
        emailOnNewNotifications?: boolean,
        emailOnDirectMessages?: boolean,
        emailPaymentReceipts?: boolean,
        emailOrderPerformance?: boolean,
        notInterestedItem?: { timelineItemId: string, timelineItemType: number },
      },
    ) {
      return axios.put(
        `${serverAddress}/users`,
        {
          username: payload.username,
          profilePicture: payload.profilePicture,
          phoneNumber: payload.phoneNumber,
          mutedUserId: payload.mutedUserId,
          webPushNotificationEnabled: payload.webPushNotificationEnabled,
          showReadReceipts: payload.showReadReceipts,
          emailOnNewNotifications: payload.emailOnNewNotifications,
          emailOnDirectMessages: payload.emailOnDirectMessages,
          emailPaymentReceipts: payload.emailPaymentReceipts,
          emailOrderPerformance: payload.emailOrderPerformance,
          webPushNotificationToken: payload.webPushNotificationToken,
          notInterestedItem: payload.notInterestedItem,
        },
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    updateOrderStatus(
      { state },
      payload: {
        orderId: string,
        trackingId?: string,
        orderStatus: OrderStatus,
        cancellationReasons: { id: number, text: string, }[],
      },
    ) {
      const crs: any[] = [];
      payload.cancellationReasons.forEach((item) => crs.push({ id: item.id, customText: item.text.trim() }));
      return axios.put(
        `${serverAddress}/orders`,
        {
          orderId: payload.orderId.trim(),
          trackingId: payload.trackingId?.trim() ?? '',
          orderStatus: payload.orderStatus,
          cancellationReasons: crs,
        },
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    submitUserRating(
      { state },
      payload: {
        userId: string,
        orderId: string,
        ratingNumber: number,
      },
    ) {
      return axios.post(
        `${serverAddress}/users/ratings`,
        payload,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    getUserFromServer({ state }, payload: string) {
      return axios.get(
        `${serverAddress}/users/${payload}`,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    fetchUserStats({ state }) {
      return axios.get(
        `${serverAddress}/users/stats`,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    fetchNotifications({ state }, payload?: { skip: number, limit: number }) {
      let url = `${serverAddress}/users/notifications?`;
      if (payload?.limit) {
        url = `${url}&limit=${payload?.limit}`;
      }
      if (payload?.skip) {
        url = `${url}&skip=${payload?.skip}`;
      }
      return axios.get(
        url,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    clearLiveNotifications({ state }) {
      const url = `${serverAddress}/users/notifications`;
      return axios.delete(
        url,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    fetchPackagesForDelivery({ state }, payload?: {
      dispatchMode: DispatchMode,
      pickupAddress: string,
      dropOffAddress: string,
      shipmentDate: Date,
      limit: number,
      skip: number
    }) {
      let url = `${serverAddress}/timeline/packages?`;

      if (payload?.limit) {
        url = `${url}&limit=${payload?.limit}`;
      }

      if (payload?.skip) {
        url = `${url}&skip=${payload?.skip}`;
      }

      if (payload?.shipmentDate) {
        url = `${url}&shipmentDate=${payload?.shipmentDate.toISOString()}`;
      }

      if (payload?.dispatchMode) {
        url = `${url}&dispatchMode=${payload?.dispatchMode}`;
      }

      if (payload?.pickupAddress) {
        url = `${url}&pickupAddress=${payload?.pickupAddress}`;
      }

      if (payload?.dropOffAddress) {
        url = `${url}&dropOffAddress=${payload?.dropOffAddress}`;
      }
      return axios.get(
        url,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    fetchOrders({ state }, payload?: {
      skip?: number,
      limit?: number,
      startDate?: Date,
      endDate?: Date,
      postedByUsername?: string,
      relatedTimelineItemid?: string,
      status?: number,
    }) {
      let url = `${serverAddress}/orders?`;

      if (payload?.limit) {
        url = `${url}&limit=${payload?.limit}`;
      }

      if (payload?.skip) {
        url = `${url}&skip=${payload?.skip}`;
      }

      if (payload?.startDate) {
        url = `${url}&startDate=${payload?.startDate}`;
      }

      if (payload?.endDate) {
        url = `${url}&endDate=${payload?.endDate}`;
      }

      if (payload?.postedByUsername) {
        url = `${url}&postedByUsername=${payload?.postedByUsername}`;
      }

      if (payload?.relatedTimelineItemid) {
        url = `${url}&relatedTimelineItemid=${payload?.relatedTimelineItemid}`;
      }

      if (payload?.status !== undefined) {
        url = `${url}&status=${payload?.status}`;
      }

      return axios.get(
        url,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    fetchOrder({ state }, trackingId: string) {
      const url = `${serverAddress}/orders/${trackingId}`;
      return axios.get(
        url,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    fetchMessageRequestDetails({ state }, payload: { packageId: string, userId: string }) {
      const url = `${serverAddress}/orders/DeliveryRequestInfo/${payload.packageId}/${payload.userId}`;
      return axios.get(
        url,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    fetchTransactions({ state }, payload?: {
      skip?: number,
      limit?: number,
      startDate?: Date,
      endDate?: Date,
      status?: number,
    }) {
      let url = `${serverAddress}/wallet/transactions?`;

      if (payload?.limit) {
        url = `${url}&limit=${payload?.limit}`;
      }

      if (payload?.skip) {
        url = `${url}&skip=${payload?.skip}`;
      }

      if (payload?.startDate) {
        url = `${url}&startDate=${payload?.startDate}`;
      }

      if (payload?.endDate) {
        url = `${url}&endDate=${payload?.endDate}`;
      }

      if (payload?.status !== undefined) {
        url = `${url}&status=${payload?.status}`;
      }

      return axios.get(
        url,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    fetchCountries({ state }) {
      const url = `${serverAddress}/users/countries`;
      return axios.get(
        url,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    async searchTimeline({ state }, payload: { skip: number, limit: number, sText: string }) {
      let url = `${serverAddress}/timeline/search?searchText=${payload.sText}`;
      if (payload?.limit) {
        url = `${url}&limit=${payload?.limit}`;
      }
      if (payload?.skip) {
        url = `${url}&skip=${payload?.skip}`;
      }
      return axios.get(
        url,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    markNotificationRead({ state }, itemId: string) {
      axios.put(
        `${serverAddress}/users/notifications/${itemId}`,
        {},
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    fetchTimelineItems({ state }, payload?: { skip: number, limit: number, itemType?: number, getMyItems: boolean }) {
      let url = `${serverAddress}/Timeline?`;
      if (payload?.limit) {
        url = `${url}&limit=${payload?.limit}`;
      }
      if (payload?.skip) {
        url = `${url}&skip=${payload?.skip}`;
      }

      if (payload?.itemType || payload?.itemType === 0) {
        if (payload?.itemType === 6) {
          url = `${url}&getMyItems=${true}`;
        } else {
          url = `${url}&itemType=${payload?.itemType}`;
        }
      }

      return axios.get(
        url,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    fetchTimelineItem({ state }, payload: { itemType: number, timelineItemId: string, skip: number, limit: number }) {
      let url = `${serverAddress}/Timeline/${payload.itemType}/${payload.timelineItemId}?`;
      if (payload.limit) {
        url = `${url}&limit=${payload?.limit}`;
      }
      if (payload.skip) {
        url = `${url}&skip=${payload?.skip}`;
      }
      return axios.get(
        url,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    sendOtp({ state }, payload?: { email: false, newPhoneNumber?: string }) {
      let url = `${serverAddress}/otp?email=${payload?.email}`;
      if (payload?.newPhoneNumber) {
        url = `${url}&newPhoneNumber=${payload?.newPhoneNumber}&isBase64=true`;
      }
      return axios.post(
        url,
        {},
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    verifyOtp({ state }, payload: string) {
      return axios.put(
        `${serverAddress}/otp?otpCode=${payload}`,
        {},
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    createPaypalOrder({ state }, payload: { currency: string, amount: string, token?: string }) {
      return axios.post(
        `${serverAddress}/Wallet/CreatePaypalOrder`,
        {
          currency: payload.currency,
          amount: payload.amount,
        },
        getAxiosConfig(payload.token ?? state.requestToken.toString()),
      );
    },
    capturePaypalOrder({ state }, payload: {orderId: string, token?: string}) {
      return axios.post(
        `${serverAddress}/Wallet/capturePaypalOrder?orderId=${payload.orderId}`,
        payload.orderId,
        getAxiosConfig(payload.token ?? state.requestToken.toString()),
      );
    },
    fetchMutedUsers({ state }, listOfUserIds: MutedUser[]) {
      return axios.all(
        listOfUserIds.map((user) => axios.get(
          `${serverAddress}/users/${user.mutedUserId}`,
          getAxiosConfig(state.requestToken.toString()),
        )),
      );
    },
    unMuteUser({ state }, userId: string) {
      return axios.delete(
        `${serverAddress}/users/unmute?userId=${userId}`,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    reportUser({ state }, payload: {reportedUserId: string, shortDescription: string, detailedDescription: string}) {
      return axios.post(
        `${serverAddress}/users/report`,
        payload,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    reportPost(
      { state },
      payload: {
        timelineItemId: string,
        itemType: string,
        shortDescription: string,
        detailedDescription: string,
      },
    ) {
      return axios.post(
        `${serverAddress}/timeline/report`,
        payload,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
    deactivateAccount({ state }, userId: string) {
      return axios.delete(
        `${serverAddress}/users/${userId}`,
        getAxiosConfig(state.requestToken.toString()),
      );
    },
  },
  modules: {
  },
});

export function useStore() {
  return baseUseStore(key);
}
