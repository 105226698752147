import axios, { AxiosResponse } from 'axios';
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  Unsubscribe,
  User,
  getAuth,
  signInWithPopup,
} from 'firebase/auth';
import store from '@/store';
import { MyUserInfo } from '@/types/MyUserInfo';
import { getAxiosConfig, serverAddress } from '@/helpers/appConfig';
import {
  collection,
  orderBy,
  getFirestore,
  limit,
  onSnapshot,
  query,
  where,
  addDoc,
} from 'firebase/firestore';
import { ChatGroup } from '@/types/MessageInfo';
import { SendmeNotification } from '@/types/SendmeNotification';

const firebaseMessagingVapidKey = process.env.VUE_APP_FIREBASE_VAPID_KEY;
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

const SSOWithGoogle = async (): Promise<User> => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  const result = await signInWithPopup(auth, provider);
  // The signed-in user info.
  const { user } = result;
  return user;
};

const SSOWithFacebook = async (): Promise<User> => {
  const auth = getAuth();
  const result = await signInWithPopup(auth, new FacebookAuthProvider());
  // The signed-in user info.
  const { user } = result;
  return user;
  // This gives you a Google Access Token. You can use it to access the Google API.
  // const credential = FacebookAuthProvider.credentialFromResult(result);
  // const token = credential?.accessToken;

  // IdP data available using getAdditionalUserInfo(result)
  // ...
};

const processUserToken = async (): Promise<void> => {
  const auth = getAuth();
  const user = await auth.currentUser;
  const token = await user?.getIdToken(true);
  if (token) {
    store.commit('SET_REQ_TOKEN', token);
    const response = await store.dispatch('getUserFromServer', user?.uid);
    const data = response.data as MyUserInfo;
    store.commit('SET_USER', data);
    store.commit('SET_LOGIN', true);
  }
};

const signOut = async () => {
  const auth = getAuth();
  await auth.signOut();
  store.commit('SET_LOGIN', false);
};

const requestPermission = () => {
  // eslint-disable-next-line no-new
  new Promise((resolve, reject) => {
    const permissionResult = Notification.requestPermission((result) => {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  });
};

const checkExists = (payload: string): Promise<AxiosResponse<any, any>> => axios.get(
  `${serverAddress}/users/exists/${payload}`,
  getAxiosConfig(''),
);

const getFileUploadInfo = (fileName: string, isProfile = false): Promise<AxiosResponse<any, any>> => axios.get(
  `${serverAddress}/FileUploadInfo/${fileName}?profile=${isProfile}`,
  getAxiosConfig(store.state.requestToken.toString()),
);

const getListOfBanks = (): Promise<AxiosResponse<any, any>> => axios.get(
  `${serverAddress}/wallet/listOfBanks`,
  getAxiosConfig(store.state.requestToken.toString()),
);

const resolveAccount = (accountNumber: string, bankCode: string): Promise<AxiosResponse<any, any>> => axios.get(
  `${serverAddress}/wallet/resolveAccount?accountNumber=${accountNumber}&bankCode=${bankCode}`,
  getAxiosConfig(store.state.requestToken.toString()),
);

const deleteFile = (publicId: string): Promise<AxiosResponse<any, any>> => axios.post(
  `${serverAddress}/DeleteFile`,
  { publicId },
  getAxiosConfig(store.state.requestToken.toString()),
);

const getPackageMeta = (): Promise<AxiosResponse<any, any>> => axios.get(
  `${serverAddress}/timeline/package/metadata`,
  getAxiosConfig(store.state.requestToken.toString()),
);

const getCancellationReasons = (): Promise<AxiosResponse<any, any>> => axios.get(
  `${serverAddress}/orders/cancellationreasons`,
  getAxiosConfig(store.state.requestToken.toString()),
);

const getCitiesInCountry = (country: string): Promise<AxiosResponse<any, any>> => axios.get(
  `${serverAddress}/users/cities/${country}`,
  getAxiosConfig(store.state.requestToken.toString()),
);

const verifyIdToken = async (payload: { token: string, uid: string }): Promise<boolean> => {
  const { uid, token } = payload;
  try {
    await axios.post(
      `${serverAddress}/verification`,
      { uid, token },
      getAxiosConfig(''),
    );
    return true;
  } catch (error: any) {
    return false;
  }
};

const createGroup = async (
  user1: {id: string, username: string, profilePicture: string },
  user2: {id: string, username: string, profilePicture: string },
) => {
  const db = getFirestore();
  const groupsCollection = collection(db, 'chatrooms');
  await addDoc(groupsCollection, {
    createdAt: new Date(),
    createdBy: user1.id,
    members: [{
      username: user1.username,
      avatar: user1.profilePicture,
      unreadMessagesCount: 0,
      deletedChat: false,
      deletedOn: new Date(),

    }, {
      username: user2.username,
      avatar: user2.profilePicture,
      unreadMessagesCount: 0,
      deletedChat: false,
      deletedOn: new Date(),

    }],
    users: [user1.id, user2.id],
    name: `${user1.id}-ft-${user2.id}`,
    hasPendingDeliveryRequest: false,
    recentMessage: {
      messageText: '',
      readBy: [user1.id],
      sentAt: new Date(),
    },
    type: 0,
  });
};

const getChatGroups = async (): Promise<Unsubscribe> => {
  const db = getFirestore();
  const groupsCollection = collection(db, 'chatrooms');
  const messagesQuery = query(
    groupsCollection,
    where(
      'users',
      'array-contains',
      store.state.user?.id,
    ),
    // orderBy('name'),
    orderBy('recentMessage.sentAt', 'desc'),
    limit(20),
  );
  const unsubscribe = await onSnapshot(messagesQuery, (snapshot) => {
    const chatGroups: ChatGroup[] = [];
    const userInfo = store.state.user!;
    const map = new Map(userInfo.mutedUsers.map((obj) => [obj.mutedUserId, obj.mutedUserId]));

    snapshot.docs.forEach(async (doc, index) => {
      const data = doc.data();
      const chatGroup = {
        createdAt: data.createdAt,
        createdBy: data.createdBy,
        id: doc.id,
        members: [{
          username: data.members[0].username,
          avatar: data.members[0].avatar,
          unreadMessagesCount: data.members[0].unreadMessagesCount,
          deletedChat: data.members[0].deletedChat,
          deletedOn: data.members[0].deletedOn?.toDate(),
        }, {
          username: data.members[1].username,
          avatar: data.members[1].avatar,
          unreadMessagesCount: data.members[1].unreadMessagesCount,
          deletedChat: data.members[1].deletedChat,
          deletedOn: data.members[1].deletedOn?.toDate(),
        }],
        name: data.name,
        hasPendingDeliveryRequest: data.hasPendingDeliveryRequest ?? false,
        recentMessage: {
          messageText: data.recentMessage.messageText,
          readBy: data.recentMessage.readBy,
          sentAt: data.recentMessage.sentAt.toDate(),
        },
        users: [...data.users],
      };

      const otherUser = chatGroup.users.filter((id) => id !== userInfo.id)[0];
      if (!map.get(otherUser)) {
        chatGroups.push(chatGroup);
      }
    });
    store.commit('SET_CHAT_GROUPS', chatGroups);
  });

  return unsubscribe;
};

const getLiveNotification = async (): Promise<Unsubscribe> => {
  const db = getFirestore();
  const notificationsCollection = collection(db, 'notification', store.state.user!.id, 'notifications');
  const notificationsQuery = query(
    notificationsCollection,
    orderBy('DateCreated', 'desc'),
  );
  const unsubscribe = await onSnapshot(notificationsQuery, (snapshot) => {
    const notifications: SendmeNotification[] = [];
    snapshot.docs.forEach(async (doc, index) => {
      const data = doc.data();
      if (data.IsSeenByUser === false) {
        notifications.push({
          id: data.Id,
          message: data.Message,
          timelineItemId: data.TimelineItemId,
          timelineItemType: data.TimelineItemType,
          relatedUserAvatar: data.RelatedUserAvatar,
          count: data.Count,
          notificationType: data.NotificationType,
          isSeenByUser: data.IsSeenByUser,
          dateCreated: data.DateCreated,
          lastModified: data.LastModified,
        });
      }
    });
    store.commit('SET_LIVE_NOTIFICATIONS', notifications);
  });

  return unsubscribe;
};

const clearLiveNotifications = async (): Promise<void> => {
  await store.dispatch('clearLiveNotifications');
};

export {
  SSOWithFacebook,
  SSOWithGoogle,
  checkExists,
  processUserToken,
  getFileUploadInfo,
  deleteFile,
  getCitiesInCountry,
  getPackageMeta,
  getListOfBanks,
  resolveAccount,
  requestPermission,
  firebaseConfig,
  firebaseMessagingVapidKey,
  signOut,
  createGroup,
  getCancellationReasons,
  getChatGroups,
  getLiveNotification,
  clearLiveNotifications,
};
